import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://637ca6bf3678e103f21c2b1a9a0ef625@o4508361189097472.ingest.us.sentry.io/4508361192767488",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()